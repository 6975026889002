import { tw } from '~/libs/tailwind/tw';

const buttonStyle = tw({
  base: [
    'outline-offset-0',
    'group/button text-title-3 font-bold leading-none bg-white',
    'inline-flex flex-row flex-nowrap whitespace-nowrap',
    'rounded-none outline-none transition-all duration-300 gap-5',
    'px-8 items-center justify-center h-[4.8rem] border-2 border-neutral-090',
  ],
  variants: {
    inverted: {
      true: [],
      false: [],
    },
    fullWidth: {
      true: 'w-full',
      false: 'w-auto',
    },
    bordered: {
      true: [''],
      false: ['border-transparent'],
    },
    disabled: {
      true: 'outline-none border-black/40 text-black/40 cursor-not-allowed',
      false: 'outline-transparent outline-[3px]',
    },
    transparent: {
      true: [],
      false: [],
    },
    asIcon: {
      true: 'justify-center p-0 w-[4.8rem]',
      false: '',
    },
  },
  compoundVariants: [
    {
      inverted: true,
      transparent: false,
      className: 'bg-white border-white',
    },
    {
      inverted: false,
      transparent: true,
      className: 'bg-transparent',
    },
    {
      inverted: false,
      disabled: false,
      transparent: true,
      className: 'hover:bg-white hover:outline-white',
    },

    {
      bordered: false,
      disabled: false,
      transparent: true,
      className: 'hover:bg-white/5 hover:outline-white',
    },
    {
      bordered: false,
      disabled: false,
      transparent: false,
      className: 'hover:bg-black/5 hover:outline-black/20',
    },
  ],
  defaultVariants: {
    asIcon: false,
    inverted: false,
    fullWidth: false,
    bordered: true,
    disabled: false,
    transparent: false,
  },
});

export { buttonStyle };
